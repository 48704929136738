import React, { createContext, useContext, useState } from "react";

// Define the context type
interface SearchContextType {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

// Create the context
const SearchContext = createContext<SearchContextType | undefined>(undefined);

// Create a provider component
export const SearchContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const value = { isOpen, setIsOpen };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

// Create a custom hook to use the context
export const useSearchContext = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error(
      "useSearchContext must be used within a SearchContextProvider"
    );
  }
  return context;
};
