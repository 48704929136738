"use client";

import useCurrentUser from "@/auth/hooks/useCurrentUser";
import { usePathname, useSearchParams } from "next/navigation";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

export default function ConfigurePostHog(): null {
	const { user, isLoading } = useCurrentUser();

	const pathname = usePathname();
	const searchParams = useSearchParams();
	const posthog = usePostHog();

	useEffect(() => {
		// Track pageviews
		if (pathname && posthog) {
			let url = window.origin + pathname;
			if (searchParams.toString()) {
				url = url + `?${searchParams.toString()}`;
			}
			posthog.capture("$pageview", {
				$current_url: url,
			});
		}
	}, [pathname, searchParams, posthog]);

	useEffect(() => {
		if (!isLoading && !!user) {
			// Identify sends an event, so you want may want to limit how often you call it
			posthog?.identify(`${user.id}`, {
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.email,
				uuid: user.uuid,
			});
		}
	}, [isLoading, user]);

	return null;
}
