import React, { createContext, useContext, useState } from "react";

// Define the context type
type MenuContextType = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

// Create the context
const MenuContext = createContext<MenuContextType | undefined>(undefined);

// Create a provider component
export const MenuContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const value = { open, setOpen };

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

// Create a custom hook to use the context
export const useMenuContext = () => {
  const context = useContext(MenuContext);
  if (context === undefined) {
    throw new Error("useMenuContext must be used within a MenuContextProvider");
  }
  return context;
};
