"use client";

import ConfigurePostHog from "@/components/ConfigurePostHog";
import { CSPostHogProvider } from "@/providers/posthog";
import * as Toast from "@radix-ui/react-toast";
import * as Tooltip from "@radix-ui/react-tooltip";

const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <CSPostHogProvider>
      <ConfigurePostHog />
      <Toast.Provider>
        <Tooltip.Provider>
          {children}
          <Toast.Viewport className="[--viewport-padding:_25px] fixed bottom-0 right-0 flex flex-col p-[var(--viewport-padding)] gap-[10px] w-[390px] max-w-[100vw] m-0 list-none z-[2147483647] outline-none" />
        </Tooltip.Provider>
      </Toast.Provider>
    </CSPostHogProvider>
  );
};

export default Providers;
