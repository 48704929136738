"use client";

import { users } from "@/db/schema";
import { useSearchParams } from "next/navigation";
import {
  createContext,
  Suspense,
  useContext,
  useEffect,
  useState,
} from "react";
import useSwr from "swr";

// Infer the type of users
type UserType = typeof users.$inferSelect;

type AuthUserType = Pick<
  UserType,
  "id" | "uuid" | "email" | "firstName" | "lastName"
> | null;

type AuthStatus = "loading" | "authenticated" | "unauthenticated";

const AuthContext = createContext<
  | {
      user: AuthUserType;
      setUser: React.Dispatch<React.SetStateAction<AuthUserType>>;
      status: AuthStatus;
      setStatus: React.Dispatch<React.SetStateAction<AuthStatus>>;
      isLoading: boolean;
      error: any;
      reset: () => void;
    }
  | undefined
>(undefined);

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context)
    throw new Error("useAuthContext must be used within a AuthContextProvider");
  return context;
};

const fetcher = (url: string) => fetch(url).then((r) => r.json());

type AuthContextInnerProps = {
  children: React.ReactNode;
};

const AuthContextInner: React.FC<AuthContextInnerProps> = ({ children }) => {
  const [user, setUser] = useState<AuthUserType>(null);
  const [status, setStatus] = useState<AuthStatus>("loading");

  const searchParams = useSearchParams();

  // Use useSwr to fetch the authentication state
  const { data, error, isLoading, mutate } = useSwr(
    "/api/auth/user/current",
    fetcher
  );

  // If the signin=success query param is present, refetch the user
  const isSigninSuccess = searchParams.get("signin") === "success";

  useEffect(() => {
    if (isSigninSuccess) {
      mutate();
    }
  }, [isSigninSuccess]);

  const reset = () => {
    setStatus("unauthenticated");
    setUser(null);
    mutate();
  };

  // Set the user and status based on the response
  useEffect(() => {
    if (isLoading) {
      setStatus("loading");
      return;
    }

    if (error) {
      console.error("Failed to fetch user:", error);
      reset();
      return;
    }

    if (data && !!data.user) {
      setUser(data.user);
      setStatus("authenticated");
    } else {
      reset();
    }
  }, [data, isLoading]);

  /*
  const fetchAuthState = async () => {
    try {
      const response = await fetch("/api/auth/user/current").then((res) =>
        res.json()
      );
      if (response.user) {
        setUser(response.user);
        setStatus("authenticated");
      } else {
        setUser(null);
        setStatus("unauthenticated");
      }
    } catch (error) {
      console.error("Failed to fetch user:", error);
      setUser(null);
      setStatus("unauthenticated");
    }
  };


  // Fetch the authentication state on first mount
  useEffect(() => {
    fetchAuthState();
  }, []);
	*/

  // Providing refreshAuthState in the context
  return (
    <AuthContext.Provider
      value={{ user, setUser, status, setStatus, isLoading, error, reset }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Suspense>
      <AuthContextInner>{children}</AuthContextInner>
    </Suspense>
  );
};
