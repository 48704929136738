import React, { createContext, useContext, useState } from "react";

// Define the context type
interface ProfilesContextType {
  selectedFilterTab: string;
  setSelectedFilterTab: (value: string) => void;
  isFilterOpen: boolean;
  setIsFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create the context
const ProfilesContext = createContext<ProfilesContextType | undefined>(
  undefined
);

// Create a provider component
export const ProfilesContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [selectedFilterTab, setSelectedFilterTab] =
    useState<string>("expertise");
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(true);

  const value = {
    selectedFilterTab,
    setSelectedFilterTab,
    isFilterOpen,
    setIsFilterOpen,
  };

  return (
    <ProfilesContext.Provider value={value}>
      {children}
    </ProfilesContext.Provider>
  );
};

// Create a custom hook to use the context
export const useProfilesContext = () => {
  const context = useContext(ProfilesContext);
  if (context === undefined) {
    throw new Error(
      "useProfilesContext must be used within a ProfilesProvider"
    );
  }
  return context;
};
