import React, { createContext, useContext, useState } from "react";

// Define the context type
interface HeaderContextType {
  transparent: boolean;
  setTransparent: (value: boolean) => void;
}

// Create the context
const HeaderContext = createContext<HeaderContextType | undefined>(undefined);

// Create a provider component
export const HeaderContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [transparent, setTransparent] = useState<boolean>(false);

  const value = { transparent, setTransparent };

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
};

// Create a custom hook to use the context
export const useHeaderContext = () => {
  const context = useContext(HeaderContext);
  if (context === undefined) {
    throw new Error(
      "useHeaderContext must be used within a HeaderContextProvider"
    );
  }
  return context;
};
