"use client";

import { AuthContextProvider } from "@/contexts/AuthContext";
import { CollectionContextProvider } from "@/contexts/CollectionContext";
import { HeaderContextProvider } from "@/contexts/HeaderContext";
import { MenuContextProvider } from "@/contexts/MenuContext";
import { SearchContextProvider } from "@/contexts/SearchContext";
import { WebsitesContextProvider } from "@/contexts/WebsitesContext";
import * as Toast from "@radix-ui/react-toast";
import * as Tooltip from "@radix-ui/react-tooltip";
import { ProfilesContextProvider } from "./ProfilesContext";

const ContextProviders = ({ children }: { children: React.ReactNode }) => {
  return (
    <AuthContextProvider>
      <MenuContextProvider>
        <SearchContextProvider>
          <WebsitesContextProvider>
            <ProfilesContextProvider>
              <CollectionContextProvider>
                <HeaderContextProvider>
                  <Toast.Provider>
                    <Tooltip.Provider>
                      {children}
                      <Toast.Viewport className="[--viewport-padding:_25px] fixed bottom-0 right-0 flex flex-col p-[var(--viewport-padding)] gap-[10px] w-[390px] max-w-[100vw] m-0 list-none z-[2147483647] outline-none" />
                    </Tooltip.Provider>
                  </Toast.Provider>
                </HeaderContextProvider>
              </CollectionContextProvider>
            </ProfilesContextProvider>
          </WebsitesContextProvider>
        </SearchContextProvider>
      </MenuContextProvider>
    </AuthContextProvider>
  );
};

export default ContextProviders;
