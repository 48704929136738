import React, { createContext, useContext, useState } from "react";

// Define the context type
interface WebsitesContextType {
  selectedContext: string;
  setSelectedContext: React.Dispatch<React.SetStateAction<string>>;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isFilterOpen: boolean;
  setIsFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create the context
const WebsitesContext = createContext<WebsitesContextType | undefined>(
  undefined
);

// Create a provider component
export const WebsitesContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(true);
  const [selectedContext, setSelectedContext] = useState<string>("popular");

  const value = {
    selectedContext,
    setSelectedContext,
    isModalOpen,
    setIsModalOpen,
    isFilterOpen,
    setIsFilterOpen,
  };

  return (
    <WebsitesContext.Provider value={value}>
      {children}
    </WebsitesContext.Provider>
  );
};

// Create a custom hook to use the context
export const useWebsitesContext = () => {
  const context = useContext(WebsitesContext);
  if (context === undefined) {
    throw new Error(
      "useWebsitesContext must be used within a WebsitesProvider"
    );
  }
  return context;
};
