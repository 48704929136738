"use client";

import type { User } from "lucia";
import useSWR, { useSWRConfig } from "swr";

type CurrentUserResponse = {
	user: User | null;
};

export const useCurrentUser = () => {
	const { mutate } = useSWRConfig();

	const { data, error, isLoading } = useSWR<CurrentUserResponse>(
		"/api/auth/user/current",
		async (url: string) => {
			const response = await fetch(url);
			return response.json();
		}
	);

	const invalidate = () => mutate("/api/auth/user/current");

	return {
		user: data?.user ?? null,
		error,
		isLoading,
		invalidate,
	};
};

export default useCurrentUser;
